import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
            reloadOnContextChange: true,
            tablet: {
              smooth: true,
              multiplier: 10,
            },
            smartphone: {
              smooth: true,
              multiplier: 10,
            }
        });

        if($(document).outerWidth() < 501) {
          $('.b-relation-pages_wrap .c-card').attr('data-scroll-speed', 0);
        }

        setTimeout(() => {
          this.scroll.update();
        }, 500);
        
        let timer = undefined;
        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);
            if (way === 'enter') {
              switch (func) {
                case "nextProject":
                  document.getElementsByClassName('e-page_intro_next_component')[0].classList.add('-next');
                  timer = setTimeout(() => {
                    if (document.getElementsByClassName('e-page_intro_next_component')[0].classList.contains('-next')){
                      document.getElementsByClassName('-next')[0].click();
                    }
                  }, 4000);
                  break;      
             }
            } else if(way === 'exit') {
              switch (func) {
                case "nextProject":
                  clearTimeout(timer);
                  document.getElementsByClassName('e-page_intro_next_component')[0].classList.remove('-next');
                  break;
              }
            }
        });

        this.scroll.on('scroll', (args) => {
            if(args.scroll.y >= 40){
              document.getElementById('header').classList.add('-scrolling');
            } else{
              document.getElementById('header').classList.remove('-scrolling');
            }
        })
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }

    scrollTo(target) {
      this.scroll.scrollTo(target);
    }

    update() {
      this.scroll.update();
    }
}
